.Toastify__toast-body {
    border: 0px !important;
    font-family: 'Rubik', sans-serif;
}

#toast-container>div {
    width: 400px !important;
    opacity: 1 !important;
}

#toast-container>.toast:before {
    position: fixed;
    font-family: 'Rubik', sans-serif;
    font-size: 24px;
    line-height: 18px;
    float: left;
    color: #FFF;
    padding-right: 0.5em;
    margin: auto 0.5em auto -1.5em;
}

.toast-success {
    background-color: #17A817 !important;
}

.toast-error {
    background-color: #D50A0A !important;
}

.toast-info {
    background-color: #5499C7 !important;
}

.prec {
    top: 25px;
    position: relative;
    font-size: 25px;
}