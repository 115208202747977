@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

body {
    font-family: 'Rubik', sans-serif;
}

.w-1024 {
    width: 1024px;
}

.m-64 {
    margin-top: 64px;
}

.text-sm {
    letter-spacing: 0.05rem;
    line-height: 1.7;
}

.wrapper {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: 100vh;
    padding: 25px;
}

.wrapper-background {
    background-color: #a7e0ce;
    background-image: linear-gradient(135deg, #a7e0ce 0%, #ffffff 51%);
    background: linear-gradient(135deg, #FFF 40%, #a7e0ce 100%);
}

.silkscreen-regular {
    font-family: "Silkscreen", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.fire-zero-state-header {
    align-items: flex-start;
    box-sizing: border-box;
    color: var(--zero-state-header-primary-color-internal);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0;
    min-height: 452px;
    overflow: visible;
    padding: 0;
    position: relative;
}

.undercut {
    background-color: #bbf7d0;
    clip-path: polygon(0px 0px, 100% 0px, 100% 80%, 0% 100%);
    height: 220px;
    position: absolute;
    width: 100%;
    opacity: 0.6;
}

.overcut {
    background-color: #86efac;
    clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 65%);
    height: 220px;
    position: absolute;
    width: 100%;
    opacity: 0.4;
}

mat-grid, [mat-grid], mat-single-grid, [mat-single-grid] {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
    margin: 0 auto;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

.title-transition {
    transition: opacity .8s cubic-bezier(0.4, 0, 0.2, 1) .8s
}

/* Custom Scrollbar */
.thin-scrollbar::-webkit-scrollbar {
    width: 4px; /* Set the width of the scrollbar */
}

.thin-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5); /* Style the scrollbar thumb */
    border-radius: 10px; /* Round the scrollbar thumb */
}

.thin-scrollbar::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1); /* Style the scrollbar track */
}
