@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --bg-color: theme('colors.gray.500');
    --text-normal: theme('colors.slate.700');
    --text-disabled: theme('colors.slate.600');
    --gradient-from: #ffffff;
    --gradient-via: #ffffff;
    --gradient-to: #a7e0ce;
}

.theme-red {
    --color-primary: theme('colors.red.600');
    --color-disabled: theme('colors.red.400');
    --color-secondary: theme('colors.red.700');
    --bg-color: theme('colors.red.200');
    --bg-light: theme('colors.red.100');
    --focus-bg-color: theme('colors.red.200');
    --focus-bg-light: theme('colors.red.100');
    --gradient-from: theme('colors.white');
    --gradient-via: theme('colors.white');
    --gradient-to: theme('colors.red.200');
}

.theme-orange {
    --color-primary: theme('colors.orange.500');
    --color-secondary: theme('colors.orange.700');
    --color-disabled: theme('colors.red.400');
    --bg-color: theme('colors.orange.200');
    --bg-light: theme('colors.orange.100');
    --focus-bg-color: theme('colors.orange.200');
    --focus-bg-light: theme('colors.orange.100');
    --gradient-from: theme('colors.white');
    --gradient-via: theme('colors.white');
    --gradient-to: theme('colors.orange.200');
}

.theme-yellow {
    --color-primary: theme('colors.yellow.500');
    --color-secondary: theme('colors.yellow.600');
    --color-disabled: theme('colors.yellow.400');
    --bg-color: theme('colors.yellow.200');
    --bg-light: theme('colors.yellow.100');
    --focus-bg-color: theme('colors.yellow.200');
    --focus-bg-light: theme('colors.yellow.100');
    --gradient-from: theme('colors.white');
    --gradient-via: theme('colors.white');
    --gradient-to: theme('colors.yellow.200');
}

.theme-lime {
    --color-primary: theme('colors.lime.600');
    --color-secondary: theme('colors.lime.700');
    --color-disabled: theme('colors.lime.400');
    --bg-color: theme('colors.lime.200');
    --bg-light: theme('colors.lime.100');
    --focus-bg-color: theme('colors.lime.200');
    --focus-bg-light: theme('colors.lime.100');
    --gradient-from: theme('colors.white');
    --gradient-via: theme('colors.white');
    --gradient-to: theme('colors.lime.200');
}

.theme-green {
    --color-primary: theme('colors.green.600');
    --color-secondary: theme('colors.green.700');
    --color-disabled: theme('colors.green.400');
    --bg-color: theme('colors.green.200');
    --bg-light: theme('colors.green.100');
    --focus-bg-color: theme('colors.green.200');
    --focus-bg-light: theme('colors.green.100');
    --gradient-from: theme('colors.white');
    --gradient-via: theme('colors.white');
    --gradient-to: theme('colors.green.200');
}

.theme-teal {
    --color-primary: theme('colors.teal.500');
    --color-secondary: theme('colors.teal.700');
    --color-disabled: theme('colors.teal.400');
    --bg-color: theme('colors.teal.200');
    --bg-light: theme('colors.teal.100');
    --focus-bg-color: theme('colors.teal.200');
    --focus-bg-light: theme('colors.teal.100');
    --gradient-from: theme('colors.white');
    --gradient-via: theme('colors.white');
    --gradient-to: theme('colors.teal.200');
}

.theme-cyan {
    --color-primary: theme('colors.cyan.600');
    --color-secondary: theme('colors.cyan.700');
    --color-disabled: theme('colors.cyan.400');
    --bg-color: theme('colors.cyan.200');
    --bg-light: theme('colors.cyan.100');
    --focus-bg-color: theme('colors.cyan.200');
    --focus-bg-light: theme('colors.cyan.100');
    --gradient-from: theme('colors.white');
    --gradient-via: theme('colors.white');
    --gradient-to: theme('colors.cyan.200');
}

.theme-blue {
    --color-primary: theme('colors.blue.500');
    --color-secondary: theme('colors.blue.700');
    --color-disabled: theme('colors.blue.400');
    --bg-color: theme('colors.blue.200');
    --bg-light: theme('colors.blue.100');
    --focus-bg-color: theme('colors.blue.200');
    --focus-bg-light: theme('colors.blue.100');
    --gradient-from: theme('colors.white');
    --gradient-via: theme('colors.white');
    --gradient-to: theme('colors.blue.200');
}

.theme-purple {
    --color-primary: theme('colors.purple.500');
    --color-secondary: theme('colors.purple.700');
    --color-disabled: theme('colors.purple.400');
    --bg-color: theme('colors.purple.200');
    --bg-light: theme('colors.purple.100');
    --focus-bg-color: theme('colors.purple.200');
    --focus-bg-light: theme('colors.purple.100');
    --gradient-from: theme('colors.white');
    --gradient-via: theme('colors.white');
    --gradient-to: theme('colors.purple.200');
}

.bg-theme {
    @apply bg-[var(--bg-color)] bg-gradient-to-br;
}

.bg-light {
    @apply bg-[var(--bg-light)];
}

.bg-color {
    @apply bg-[var(--bg-color)];
}

.to-gradient {
    @apply to-[var(--gradient-to)];
}

.via-gradient {
    @apply via-[var(--gradient-via)];
}

.from-gradient {
    @apply from-[var(--gradient-from)];
}

.text-normal {
    @apply text-[var(--text-normal)];
}

[aria-disabled="true"] {
    cursor: not-allowed;
    opacity: 0.6; /* visually indicate it's disabled */
}

.text-primary {
    @apply text-[var(--color-primary)];
}

.text-secondary {
    @apply text-[var(--color-secondary)];
}

.bg-primary {
    @apply bg-[var(--color-primary)];
}

.border-primary {
    @apply border-[var(--color-primary)];
}

.bg-secondary {
    @apply bg-[var(--color-secondary)];
}

/* Hover states for background and text */
.hover\:bg-secondary:hover,
.focus\:bg-secondary:focus {
    @apply bg-[var(--color-secondary)];
}

.hover\:text-primary:hover {
    @apply text-[rgb(var(--color-primary))];
}

.hover\:text-secondary:hover {
    @apply text-[var(--color-secondary)];
}

.hover\:border-primary:hover,
.focus\:border-primary:focus {
    @apply border-[var(--color-primary)];
}

.hover\:border-secondary:hover,
.focus\:border-secondary:focus {
    @apply border-[var(--color-secondary)];
}

.hover\:ring-primary:hover,
.focus\:ring-primary:focus {
    @apply ring-[var(--color-primary)];
}

.hover\:ring-secondary:hover,
.focus\:ring-secondary:focus {
    @apply ring-[var(--color-secondary)];
}

.disabled\:bg-primary:disabled,
.hover\:bg-primary:disabled:hover {
    @apply bg-[var(--color-disabled)];
}

.focus\:outline-primary:focus {
    @apply outline-[var(--color-primary)] outline-2 outline-offset-2;
}